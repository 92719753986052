import { FuseConfigService } from '@fuse/services/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { AuthService } from './../../../app/services/auth.service';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { navigationTopAdmin } from '../../../app/navigation/navigation.top.admin';
import { Socket } from 'ngx-socket-io';
import axios from 'axios';
import { Router } from '@angular/router';
import { EmpresaService } from 'app/services/empresa.service';
import { resolve } from 'path';


@Component({
    selector   : 'shortcuts-maestros',
    templateUrl: './shortcuts-maestros.component.html',
    styleUrls  : ['./shortcuts-maestros.component.scss']
})
export class FuseShortcutsMaestrosComponent implements OnInit, AfterViewInit, OnDestroy
{
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;
    empresas = [];
    empresaSelected: any = {};
    empresaFormControl = new FormControl();

    @Input()
    layout = 'vertical';
    
    @Input()
    navigation: any;

    @Input() 
    permission: string;

    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcutsMaestros')
    shortcutsEl: ElementRef;

    // Chat listeners
    unReadChatsSessions = {}; //Key par session, user values
    unReadChatsCount = 0;
    chatNewMessageListeners;
    chatRemoveUnreadListeners;
    hiddenSelectEmpresa: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private socket: Socket,
        private authService : AuthService,
        private router: Router,
        private _matSnackBar: MatSnackBar,
        private _fuseConfigService: FuseConfigService,
        private empresaService: EmpresaService,

    ) {
        // Set Permission to access case use
        this.permission = sessionStorage.getItem('currentLoginType');
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.shortcutItems = navigationTopAdmin;
        // Subscribe to the config changes
        this._fuseConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((settings) => {
            this.hiddenSelectEmpresa = settings.layout.toolbar.hiddenSelectEmpresa === true;

            if (!this.hiddenSelectEmpresa) {
                this.obtenerEmpresasDelUsuario();
            }
        });

        
    }

    obtenerEmpresasDelUsuario = () => {
        this.empresas = new Array();
        if (this.authService.getLoggedUser()) {   
            const userId = this.authService.getLoggedUser()['sub'];
            const tenant = localStorage.getItem("tenant");

            //Primero busca la empresa Seleccionada 
            axios.get(`${environment.url}empresa/${userId}/${tenant}` )
            .then(empresaSeleccionadaResponse => { 
                
                if(empresaSeleccionadaResponse.data.success){

                    //Carga la empresa seleccionada, como primer elemento del Array
                    this.empresas.push({
                        id : empresaSeleccionadaResponse.data.result.emu_secuencia,
                        nombre : empresaSeleccionadaResponse.data.result.emp_organizacion,
                        tenant : empresaSeleccionadaResponse.data.result.emu_tenant,
                        countDtes : empresaSeleccionadaResponse.data.result.count_dtes,
                        //creacion : empresaUsuario.emu_fecha,
                        role : empresaSeleccionadaResponse.data.result.emu_role,
                    });
                    this.empresaSelected = {...this.empresas[0]};        //Selecciona empresa

                    axios.get(`${environment.url}empresas/${userId}/%20/0/5` )
                    .then(otrosEmpresasResponse => { 
                        if(otrosEmpresasResponse.data.success){

                            let c = 0;
                            for (let i = 0; i < otrosEmpresasResponse.data.result.length; i++) {
                                const empresaUsuario = otrosEmpresasResponse.data.result[i];
                                if (empresaUsuario.emu_secuencia != this.empresaSelected.id) { //Por si en la consulta vuelve a venir el seleccionado, ya no incluye
                                    
                                    if (+empresaUsuario.emu_estado == 1) { //Debe estar activo para mostrar en la lista.
                                        this.empresas.push({
                                            id : empresaUsuario.emu_secuencia,
                                            nombre : empresaUsuario.emp_organizacion,
                                            tenant : empresaUsuario.emu_tenant,
                                            countDtes : empresaUsuario.count_dtes,
                                            //creacion : empresaUsuario.emu_fecha,
                                            role : empresaUsuario.emu_role
                                        });

                                        c++;
                                        if (c > 4) break; //Como recupera 5, para que en la 4ta carga ya no haga nada
                                    }
                                }
                            }
        
//                            this.empresaSelected = this.empresas.filter( e => e.tenant === tenant)[0].id;
        
                            this.empresas.push({
                                id : -1,
                                nombre : "Todas las Empresas",
                                tenant : null,
                            });

                        }

                    });

                } else {
                    this._matSnackBar.open(empresaSeleccionadaResponse.data.error, 'Error', {
                        verticalPosition: 'top',
                        duration: 3000
                    });
                }
                
            });
        }
    }

    selectEmpresa(empresaId: number) {
        console.log("select Empresa", empresaId);
        if (empresaId === -1) {
            this.router.navigate(["/empresa-select"]);
        } else {
            let empresa = this.empresas.filter(e => e.id === empresaId)[0];

            let userData: any = JSON.parse(localStorage.getItem('ud'));
            this.empresaService.changeEmpresa(empresa, userData);
            
            if (empresa.countDtes && +empresa.countDtes > 0) {
                //this.router.navigate(["/dashboard"]);
                //window.location.reload();
                window.location.href = '/dashboard';

            } else {
                
                //this.router.navigate(["/dashboard"]);
                //window.location.reload();
                window.location.href = '/dashboard';
            }
    
            this._matSnackBar.open(`${empresa.nombre} seleccionada...!`, 'Info', {
                verticalPosition: 'top',
                duration: 3000
            });    
        }
    }

    ngAfterViewInit(): void {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if ( this._mediaObserver.isActive('gt-sm') )
                {
                    this.hideMobileShortcutsPanel();
                }
            });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Search
     *
     * @param event
     */
    /*search(event): void {
        console.log("pasa por aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", event)
        const value = event.target.value.toLowerCase();

        if ( value === '' )
        {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }*/

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void {
        event.stopPropagation();

        for ( let i = 0; i < this.shortcutItems.length; i++ )
        {
            if ( this.shortcutItems[i].url === itemToToggle.url )
            {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }
}
