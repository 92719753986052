import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TabsControlService } from 'app/services/tabs.service';

@Component({
    selector     : 'tab-content',
    templateUrl  : './tab-content.component.html',
    styleUrls    : ['./tab-content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TabContentComponent implements OnInit {
    hideTabBarHeader = false;   //Si es true, indica que no se debe mostrar la barra de seleccion de Tabs.
                                //Util para cuando la página tendrá una sola pestaña
    switchFirstTime = true;

    tabSelected;

    /**
     * Constructor
     */
    constructor(
        public tabsControlService : TabsControlService,
        private router : Router
    )
    {
    }

    ngOnInit(): void {
        this.tabSelected = this.tabsControlService.tabs[this.tabsControlService.selectedIndex];
        
    }
    closeTab (innerTabs, key) {
        
        const index = innerTabs.findIndex(tab => tab.key == key);
        //console.log("Index seleccionado", innerTabs, key, index);
        innerTabs.splice(index, 1);
    }

    /**
     * Filtra las tabs que tienen layout == tab para mostrar en la lista de pestanas de la Aplicacion
     * @param tab 
     * @param filterArgs 
     * @returns 
     */
     filterNoTabFirstExecution = (tab) => {
        if (this.switchFirstTime) {
            this.switchFirstTime = false;

            if (tab.innerTabs[0] && (tab.innerTabs[0].data.layout === 'tab')) {
                //console.log("tab.innerTabs[0].data ", tab.innerTabs[0]);
                //console.log("XXXXXXXXXXXXXXXXXXXXXXXX---> ", tab.innerTabs[0].data.hideTabBarHeader);
                //console.log("XXXXXXXXXXXXXXXXXXXXXXXX---> ", tab);
                
                if (tab.innerTabs[0].data.hideTabBarHeader && tab.innerTabs[0].data.hideTabBarHeader === true) {
                    this.hideTabBarHeader = true;
                }
                
              return true;
            }
            return false;
        }
        return false;
    }

    /**
     * Filtra las tabs que tienen layout == tab para mostrar en la lista de pestanas de la Aplicacion
     * @param tab 
     * @param filterArgs 
     * @returns 
     */
    filterNoTab = (tab) => {

        this.hideTabBarHeader = false;
        if (tab.innerTabs && tab.innerTabs[0] && (tab.innerTabs[0].data.layout === 'tab')) {
            //console.log("tab.innerTabs[0].data ", tab.innerTabs[0]);
            console.log("---> ", tab.innerTabs[0].data.hideTabBarHeader);
            
            if (tab.innerTabs[0].data.hideTabBarHeader && tab.innerTabs[0].data.hideTabBarHeader === true) {
                this.hideTabBarHeader = true;
            } else {
            }
            
          return true;
        }
        return false;
    }

    //Tab Principal - Oculto
    mainSelectedTabChange(mainTabChangeEvent: MatTabChangeEvent) {
        //console.log("selectedTabChange =====================================", mainTabChangeEvent.index);
        //console.log("selectedTabChange =====================================", mainTabChangeEvent);
        const tab = this.tabsControlService.tabs[mainTabChangeEvent.index];
        //console.log("selectedTabChange =====================================", tab);
        //console.log("this.hideTabBarHeader", this.hideTabBarHeader);
        
        //this.filterNoTab(this.tabsControlService.tabs[mainTabChangeEvent.index]);
        this.hideTabBarHeader = false;
        if (tab.innerTabs && tab.innerTabs[0].data.hideTabBarHeader && tab.innerTabs[0].data.hideTabBarHeader === true) {
            this.hideTabBarHeader = true;
        }
        
    }

    mainSelectedIndexChange(tabChangeEvent: MatTabChangeEvent) {
        //console.log("selectedIndexChange =====================================", tabChangeEvent.index);
        
    }

    //InnerTab de cada pagina - visible generalmente
    selectedTabChange(tabChangeEvent: MatTabChangeEvent) {
        //console.log("selectedTabChange =====================================", tabChangeEvent.index);
        //this.filterNoTab(tabChangeEvent);
        const innerTabSelected = this.tabsControlService.getTabSelected().innerTabs[tabChangeEvent.index];
        this.router.navigate([innerTabSelected.url]);
    }

    selectedIndexChange(tabChangeEvent: MatTabChangeEvent) {
        //console.log("selectedIndexChange =====================================", tabChangeEvent.index);
        
    }
    focusChange(tabChangeEvent: MatTabChangeEvent) {
        //console.log("focusChange", tabChangeEvent);
        //this.filterNoTab(tabChangeEvent);
    }
    
}
